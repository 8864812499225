import React, { FC } from 'react';

import { useGetScreenWidth } from '../../../hooks/useGetScreenWidth';

import Links from '../../../components/pagesComponents/CaseStudy/Links';
import WalmartLayout from '../../../components/PagesLayouts//WalmartLayout';
import Card from '../../../components/pagesComponents/CaseStudy/Card';
import Video from '../../../components/pagesComponents/MainPage/Video';
import FixedButton from '../../../components/common/FixedButton';

import flyer1 from '../../../assets/img/CaseStudy/Walmart/flyers/flyer1.png';
import flyer2 from '../../../assets/img/CaseStudy/Walmart/flyers/flyer2.png';
import flyer3 from '../../../assets/img/CaseStudy/Walmart/flyers/flyer3.png';
import flyer4 from '../../../assets/img/CaseStudy/Walmart/flyers/flyer4.png';
import walmartLogo from '../../../assets/img/companies/walmart.svg';

const ProjectGallery: FC = () => {
    const isMobile = useGetScreenWidth();

    return (
        <WalmartLayout>
            <div className="scope-of-project__cards">
                <div className="scope-of-project__cards-wrapper scope-of-project__cards-wrapper--mb">
                    <Card img={flyer1} size="xl" imageType="xl" />
                    <Card img={flyer2} size="medium" imageType="medium" />
                </div>
                <div className="scope-of-project__cards-wrapper scope-of-project__cards-wrapper--mb-60">
                    <Card img={flyer3} size="small" imageType="small" />
                    <Card img={flyer4} size="large" imageType="large" />
                </div>
                <Video
                    logo={walmartLogo}
                    type="secondary"
                    slogan={
                        isMobile
                            ? 'Blackbird has been an incredible partner to work with throughout the development of our new tooling. They are collaborative in brainstorming, planning and executing.'
                            : 'Blackbird has been an incredible partner to work with throughout the development of our new tooling. They are collaborative in brainstorming, planning and executing and are always open to working through some tough problems together. Blackbird’s communication and support throughout the entire project allowed us to build a very robust tool that is flexible, comprehensive and easy to use.'
                    }
                    position="Alysha V., Sr. Manager, Operations"
                    videoNameStyle="light"
                />
            </div>
            <div className="scope-of-project__body scope-of-project__body--mw">
                <Links />
            </div>
            <FixedButton />
        </WalmartLayout>
    );
};

export default ProjectGallery;
